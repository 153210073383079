import React, { useEffect, useState } from 'react';
import { AppContext, AppInitialProps } from 'next/app';
import ConnectedIntlProvider, { LangContext } from '../src/ConnectedIntlProvider';
import Favicon from '../components/head';

import '../public/styles/stylesGlobalCSS.css';
import { Provider, useSession } from 'next-auth/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import { config } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';
config.autoAddCss = false;

import 'vanilla-cookieconsent/dist/cookieconsent.css';
import * as CookieConsent from 'vanilla-cookieconsent';

import Intercom from '@intercom/messenger-js-sdk';
import { getCookie, setCookie } from 'cookies-next';
import * as Sentry from '@sentry/nextjs';
import { AppProvider, useAppContext } from '../context/AuthContext';
import { log } from '../components/logger';

// Set your APP_ID
const APP_ID = 'jlq7hw5m';

const getBrowserLocale = () => {
    try {
        return window.navigator.language.split('-')[0].toLowerCase();
    } catch {
        return 'en';
    }
};

// Wrapovací komponenta, která je uvnitř AppProvider a má tedy přístup ke kontextu
const AppContent = ({ Component, pageProps, language, setLanguage, apiVersion }) => {
    // Přístup ke kontextovým datům
    const { authData, refreshAuthInfo, setLanguageFunction } = useAppContext();

    // Příklad využití – zde můžeš volat refresh nebo pracovat s authData
    // console.log("Aktuální authData:", authData);

    // Příklad využití Intercom, pokud jsou dostupné potřebné údaje
    useEffect(() => {
        if (
            authData.userName &&
            authData.userUUID &&
            authData.userEmail &&
            authData.companyUUID &&
            authData.companyName &&
            typeof window !== 'undefined'
        ) {
            const crypto = require('crypto');
            const secretKey = 'ijkj7eBH1IzkIJNkPBuSXeHwnyvnOoSpRHT7PWSm';
            const hash = crypto
                .createHmac('sha256', secretKey)
                .update(authData.userUUID)
                .digest('hex');

            const newWidth = window.innerWidth;
            const newHeight = window.innerHeight;

            Intercom({
                app_id: APP_ID,
                action_color: '#3D55D9',
                background_color: '#3D55D9',
                web_app_version: '0.6.1',
                plugin_version: '1.0.3',
                api_version: apiVersion,
                language: language,
                resolutionWidth: newWidth,
                resolutionHeight: newHeight,
                environment: process.env.NEXT_PUBLIC_NODE_ENV,
                user_id: authData.userUUID,
                name: authData.userName,
                email: authData.userEmail,
                phone: authData.userPhone,
                company: {
                    id: authData.companyUUID,
                    name: authData.companyName,
                },
                created_at: Date.now(),
                user_hash: hash,
            });
        }
    }, [
        authData.userUUID,
        authData.companyUUID,
        authData.userName,
        authData.userEmail,
        authData.companyName,
        apiVersion,
        language,
    ]);

    return <Component {...pageProps} />;
};

const MyApp = ({ Component, pageProps }: AppContext & AppInitialProps) => {
    const [language, setLanguage] = useState(getBrowserLocale()); // Default language
    const [session, loading] = useSession();
    const [apiVersion, setAPIversion] = useState('0.0.0');
    const [isConsentGivenGoogleAnalytics, setIsConsentGivenGoogleAnalytics] = useState(false);
    const [isConsentGivenPlerdy, setIsConsentPlerdy] = useState(false);

    // Nastavení cookie consentu
    useEffect(() => {
        CookieConsent.run({
            categories: {
                necessary: { enabled: true, readOnly: true },
                analytics: { enabled: true },
                heatmap: { enabled: true },
            },
            mode: 'opt-in',
            autoShow: true,
            guiOptions: {
                consentModal: {
                    layout: 'box',
                    position: 'bottom left',
                    flipButtons: false,
                    equalWeightButtons: true,
                },
            },
            language: {
                default: language === 'cs' ? 'cs' : 'en',
                translations: {
                    cs: {
                        consentModal: {
                            title: 'Na vašem soukromí nám záleží',
                            description:
                                'Na tomto webu používáme soubory cookies k zajištění funkčnosti webu a s vaším souhlasem i cookies k analýze výkonu našich webových stránek. Kliknutím na tlačítko „Souhlasím“ souhlasíte s využíváním všech typů používaných cookies a předáním údajů o chování na webu. Více o souborech cookie.',
                            acceptAllBtn: 'Souhlasím se všemi',
                            acceptNecessaryBtn: 'Nesouhlasím se všemi',
                            showPreferencesBtn: 'Podrobné nastavení',
                        },
                        preferencesModal: {
                            title: 'Podrobné nastavení cookies',
                            acceptAllBtn: 'Souhlasím',
                            acceptNecessaryBtn: 'Nesouhlasím',
                            savePreferencesBtn: 'Uložit nastavení',
                            closeIconLabel: 'Zavřít okno',
                            sections: [
                                {
                                    description:
                                        'Cookies na našem webu používáme k zajištění základních funkcí webu a k analýze výkonu webu. Souhlas pro používání cookies můžete kdykoliv změnit.',
                                },
                                {
                                    title: 'Nezbytné cookies',
                                    description:
                                        'Technické cookies jsou nezbytné pro správné fungování webu a všech funkcí. Tyto soubory zajišťují základní funkce stránky jako je navigace nebo přihlášení. Bez technických cookies by náš web nebyl funkční, proto jsou ve výchozím nastavení povoleny a nelze je zakázat. Tyto soubory cookies neukládají žádné osobně identifikovatelné informace.',
                                    linkedCategory: 'necessary',
                                },
                                {
                                    title: 'Analytické cookies',
                                    description:
                                        'Analytické cookies shromažďujeme pro měření výkonu našeho webu. Pomocí nich určujeme kolik uživatelů přišlo na náš web a z jakého zdroje. Tato data zpracováváme souhrnně, bez možnosti ukázání na konkrétní uživatele našeho webu. Sbírání těchto cookies nemusíte povolit. Pokud je nepovolíte, ztratíme možnost kompletní analýzy našeho webu a následné optimalizaci. Ke sběru dat využíváme službu Google Analytics.',
                                    linkedCategory: 'analytics',
                                },
                                {
                                    title: 'Heatmapy and chování uživatelů',
                                    description:
                                        'Shromažďujeme data o vašich interakcích na webu (např. kliknutí, pohyb myši) za účelem vytváření heatmap, které nám pomáhají vylepšit uživatelskou zkušenost. Tato data jsou sbírána prostřednictvím služby Plerdy.',
                                    linkedCategory: 'heatmap',
                                },
                            ],
                        },
                    },
                    en: {
                        consentModal: {
                            title: 'Your privacy is important to us',
                            description:
                                'On this website, we use cookies to ensure the functionality of the website and, with your consent, cookies to analyze the performance of our website. By clicking the "Accept all" button, you agree to the use of all types of cookies used and the transfer of data about behavior on the website.',
                            acceptAllBtn: 'Accept all',
                            acceptNecessaryBtn: 'Reject all',
                            showPreferencesBtn: 'Manage preferences',
                        },
                        preferencesModal: {
                            title: 'Cookie preferences',
                            acceptAllBtn: 'Accept all',
                            acceptNecessaryBtn: 'Reject all',
                            savePreferencesBtn: 'Save preferences',
                            closeIconLabel: 'Close',
                            sections: [
                                {
                                    description:
                                        'We use cookies on our website to ensure basic website functions and to analyze website performance. You can change your consent to the use of cookies at any time.',
                                },
                                {
                                    title: 'Necessary cookies',
                                    description:
                                        'Technical cookies are necessary for the proper functioning of the website and all functions. These files ensure the basic functions of the site such as navigation or login. Our website would not function without technical cookies, therefore they are enabled by default and cannot be disabled. These cookies do not store any personally identifiable information.',
                                    linkedCategory: 'necessary',
                                },
                                {
                                    title: 'Analytic cookies',
                                    description:
                                        'We collect analytical cookies to measure the performance of our website and advertising campaigns. We use them to determine how many users came to our website and from which source. We process this data in aggregate, without the possibility of pointing to specific users of our website. You do not have to allow the collection of these cookies. If you do not allow them, we will lose the possibility of a complete analysis of our website and subsequent optimization. For data collection purposes, we use Google Analytics.',
                                    linkedCategory: 'analytics',
                                },
                                {
                                    title: 'Heatmaps and User Behavior',
                                    description:
                                        'We collect data about your interactions on the website (e.g., clicks, mouse movements) in order to generate heatmaps that help us improve the user experience. This data is collected through the Plerdy service.',
                                    linkedCategory: 'heatmap',
                                },
                            ],
                        },
                    },
                },
            },
            onConsent: function () {
                if (CookieConsent.acceptedCategory('analytics')) {
                    setIsConsentGivenGoogleAnalytics(true);
                } else {
                    setIsConsentGivenGoogleAnalytics(false);
                }
                if (CookieConsent.acceptedCategory('heatmap')) {
                    setIsConsentPlerdy(true);
                } else {
                    setIsConsentPlerdy(false);
                }
            },
            onChange: function () {
                if (CookieConsent.acceptedCategory('analytics')) {
                    setIsConsentGivenGoogleAnalytics(true);
                } else {
                    setIsConsentGivenGoogleAnalytics(false);
                }
                if (CookieConsent.acceptedCategory('heatmap')) {
                    setIsConsentPlerdy(true);
                } else {
                    setIsConsentPlerdy(false);
                }
            },
        });
        CookieConsent.setLanguage(language);
    }, [language]);

    useEffect(() => {
        const googleAnalyticsId = 'G-GB5GYJ3P0D';

        const appendScript = (id, src, inlineCode) => {
            if (!document.getElementById(id)) {
                const script = document.createElement('script');
                script.id = id;
                script.async = true;
                script.src = src;
                document.head.appendChild(script);

                if (inlineCode) {
                    const inlineScript = document.createElement('script');
                    inlineScript.id = `${id}-inline`;
                    inlineScript.innerHTML = inlineCode;
                    document.head.appendChild(inlineScript);
                }
            }
        };

        const removeScript = id => {
            const script = document.getElementById(id);
            const inlineScript = document.getElementById(`${id}-inline`);
            if (script) script.remove();
            if (inlineScript) inlineScript.remove();
        };

        if (isConsentGivenGoogleAnalytics) {
            appendScript(
                'google-analytics',
                `https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`,
                `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', '${googleAnalyticsId}');
                `,
            );
        } else {
            removeScript('google-analytics');
        }

        if (isConsentGivenPlerdy) {
            if (process.env.NEXT_PUBLIC_NODE_ENV === 'production') {
                appendScript(
                    'plerdy',
                    null,
                    ' var _protocol = document.location.protocol === "https:" ? "https://" : "http://";\n' +
                    ' var _site_hash_code = "a0dc3effd0d8eaca083f854e4884b555", _suid = 57270;\n' +
                    ' var plerdyScript = document.createElement("script");\n' +
                    ' plerdyScript.setAttribute("defer", "");\n' +
                    ' plerdyScript.dataset.plerdymainscript = "plerdymainscript";\n' +
                    ' plerdyScript.src = "https://a.plerdy.com/public/js/click/main.js?v=" + Math.random();\n' +
                    ' var plerdymainscript = document.querySelector("[data-plerdymainscript=\'plerdymainscript\']");\n' +
                    ' if (plerdymainscript) plerdymainscript.parentNode.removeChild(plerdymainscript);\n' +
                    ' try { document.head.appendChild(plerdyScript); } catch (t) { console.log(t, "unable to add script tag"); }',
                );
            } else {
                appendScript(
                    'plerdy',
                    null,
                    ' var _protocol = document.location.protocol === "https:" ? "https://" : "http://";\n' +
                    ' var _site_hash_code = "6ca0b98168c34220ec29f69b5959f8a5", _suid = 57256;\n' +
                    ' var plerdyScript = document.createElement("script");\n' +
                    ' plerdyScript.setAttribute("defer", "");\n' +
                    ' plerdyScript.dataset.plerdymainscript = "plerdymainscript";\n' +
                    ' plerdyScript.src = "https://a.plerdy.com/public/js/click/main.js?v=" + Math.random();\n' +
                    ' var plerdymainscript = document.querySelector("[data-plerdymainscript=\'plerdymainscript\']");\n' +
                    ' if (plerdymainscript) plerdymainscript.parentNode.removeChild(plerdymainscript);\n' +
                    ' try { document.head.appendChild(plerdyScript); } catch (t) { console.log(t, "unable to add script tag"); }',
                );
            }
        } else {
            removeScript('plerdy');
        }

        return () => {
            removeScript('google-analytics');
            removeScript('plerdy');
        };
    }, [isConsentGivenGoogleAnalytics, isConsentGivenPlerdy]);

    return (
        <LangContext.Provider value={{ language, setLanguage }}>
            <ConnectedIntlProvider>
                <AppProvider >
                    <Provider session={pageProps.session}>
                        {/* Místo přímého volání Component zde používáme AppContent,
                která nám umožní pracovat s kontextem v _app.tsx */}
                        <AppContent
                            Component={Component}
                            pageProps={pageProps}
                            language={language}
                            setLanguage={setLanguage}
                            apiVersion={apiVersion}
                        />
                        <Favicon />
                    </Provider>
                </AppProvider>
            </ConnectedIntlProvider>
        </LangContext.Provider>
    );
};

export default MyApp;
